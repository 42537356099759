import styled from "@emotion/styled";

export const Content = styled.div`
    background: #151f28;
    color: white;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    height: 100vh;
    @supports (height: 100dvh) {
        height: 100dvh;
    }

    a {
        color: white;
    }
`;
