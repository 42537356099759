import {call, takeLatest, takeEvery, spawn} from "redux-saga/effects";
import root from "window-or-global";
import {serializeError} from "@atg-shared/log";
import {DepositActionConstants} from "@atg-payment-shared/deposit-types";

import changePasswordSaga from "@atg-global-shared/change-password-feature/domain/changePasswordSaga";
import {
    LOG,
    LOGIN_COMPLETE,
    LOGIN_LOADED,
    LOGIN_CANCELLED,
    OPEN_COUPON,
    DEPOSIT_FINISHED,
    ACCESS_TOKEN_REFRESHED,
    type PostMessagePayload,
    ACCOUNT_REMOVAL,
} from "./appBridgeActions";

type AppBridgeAction = {type: string; payload: PostMessagePayload};

export function* postMessage(action: AppBridgeAction) {
    try {
        const {type, payload} = action;

        // For some reason, the Account Deposit page effectively reloads if it changes the location...
        if (
            action.type === DepositActionConstants.DEPOSIT_SUCCESS &&
            root.location.href.includes("account")
        )
            return;

        yield call(window.postMessage, {type, payload});
    } catch (error: unknown) {
        console.error("AppBridgeSaga failing.", {error: serializeError(error), action});
    }
}

function* logMessage(action: AppBridgeAction) {
    try {
        if (root.location.href.indexOf("debug") === -1) return;

        yield call(postMessage, action);
    } catch (error: unknown) {
        console.error("AppBridgeSaga failing.", {error: serializeError(error), action});
    }
}

function* postAccountRemovalMessage(action: AppBridgeAction) {
    yield call(postMessage, action);

    yield call(window.postMessage, {
        type: "log",
        payload: {message: "Removal complete."},
    });
}

export default function* appBridgeSaga() {
    yield takeLatest(LOGIN_COMPLETE, postMessage);
    yield takeLatest(LOGIN_LOADED, postMessage);
    yield takeLatest(LOGIN_CANCELLED, postMessage);
    yield takeLatest(OPEN_COUPON, postMessage);
    yield takeLatest(DEPOSIT_FINISHED, postMessage);
    yield takeLatest(DepositActionConstants.DEPOSIT_SUCCESS, postMessage);
    yield takeLatest(ACCESS_TOKEN_REFRESHED, postMessage);
    yield takeLatest(ACCOUNT_REMOVAL, postAccountRemovalMessage);
    yield takeEvery(LOG, logMessage);
    yield spawn(changePasswordSaga);
}
