import * as React from "react";
import * as Redux from "react-redux";
import {Button} from "@atg-ui/toolkit";
import {useWelcomeNewCustomerDispatch} from "@atg-global-shared/welcome-new-customer/src/hooks";
import {setSource} from "@atg-global-shared/welcome-new-customer-data-access/src/slices/welcomeNewCustomerSlice";
import {Source} from "@atg-global-shared/welcome-new-customer-types";
import {trackGlobalEvent} from "@atg-global-shared/analytics";

import {MemberActions} from "@atg-global-shared/member-data-access";
import * as LoginTypes from "@atg-login-shared/types";
import {LoginSelectors} from "@atg-login-shared/data-access";
import {useBreakpoint} from "atg-breakpoints";

function CreateAccount() {
    const dispatch = Redux.useDispatch();
    const welcomeNewCustomerDispatch = useWelcomeNewCustomerDispatch();
    const isBelowMd = !useBreakpoint("MD");
    const authMethod = Redux.useSelector(LoginSelectors.selectStoredAuthMethod);
    // authMethod stores the last method used to authenticate, and if it's the authenticator selector, it means the user is new
    const isNewUser = authMethod.method === LoginTypes.CurityState.AUTHENTICATOR_SELECTOR;

    if (!isNewUser) return null;

    const handleClick = () => {
        dispatch(MemberActions.startRegisterFlow({triggeredBy: {type: "menu"}}));
        welcomeNewCustomerDispatch(setSource(Source.menu));
        trackGlobalEvent({
            event: "memberRegisterStart",
        });
    };

    return (
        <Button
            color="success"
            href="/skapakonto"
            variant="contained"
            fullWidth={isBelowMd}
            onClick={handleClick}
            data-test-id="create-account"
        >
            Öppna konto
        </Button>
    );
}

export default CreateAccount;
