import * as React from "react";
import type {PlayerEngine} from "@atg-play-shared/hls-js-player";
import {PlayerBrands} from "@atg-play-shared/hls-js-player";

// @todo fix circular dependency error
// eslint-disable-next-line @nx/enforce-module-boundaries
import {PlayerIds} from "@atg-play/player-utils/constants";
import log, {serializeError} from "@atg-shared/log";

/**
 * This context is used to inject googles sdk for chromecast sender application and handle the connection to chromecast.
 * General information about chromecast sender application the the flow between sender and receiver applications for chromecast can be found in the docs.
 * Main page: https://developers.google.com/cast/docs/overview
 * Web sender specific: https://developers.google.com/cast/docs/web_sender
 *
 * The sdk should only be injected once and there should only be one connection per page to your chromecast.
 * Therefore this context is put inside the frame microfrontend and control every videoplayers connection to chromecast on atg.se
 * This also means that if the "global-player" is used somewhere outside atg.se chromecast will not work unless this context is added as well.
 */

interface CastContextTypes {
    remotePlayer: PlayerEngine | null;
    setId: (id: PlayerIds | string) => void;
    connectedPlayerId: PlayerIds | string;
    isChromecastAvailable: boolean;
    isChromecastConnected: boolean;
    isRemotePlayerPlaying: boolean;
    posDur: {
        position: number;
        duration: number;
    };
}
declare global {
    interface Window {
        castContext?: React.Context<CastContextTypes>;
    }
}
const chromeCastSplunkString = "ChromeCast:";

const getErrorMessage = (error: chrome.cast.ErrorCode) => {
    switch (error) {
        case chrome.cast.ErrorCode.API_NOT_INITIALIZED:
            return `${chromeCastSplunkString} The API is not initialized.${chrome.cast.ErrorCode.API_NOT_INITIALIZED}`;
        case chrome.cast.ErrorCode.CANCEL:
            return `${chromeCastSplunkString} The operation was canceled by the user${chrome.cast.ErrorCode.CANCEL}`;
        case chrome.cast.ErrorCode.CHANNEL_ERROR:
            return `${chromeCastSplunkString} A channel to the receiver is not available.${chrome.cast.ErrorCode.CHANNEL_ERROR}`;
        case chrome.cast.ErrorCode.EXTENSION_MISSING:
            return `${chromeCastSplunkString} The Cast extension is not available.${chrome.cast.ErrorCode.EXTENSION_MISSING}`;
        case chrome.cast.ErrorCode.INVALID_PARAMETER:
            return `${chromeCastSplunkString} The parameters to the operation were not valid.${chrome.cast.ErrorCode.INVALID_PARAMETER}`;
        case chrome.cast.ErrorCode.RECEIVER_UNAVAILABLE:
            return `${chromeCastSplunkString} No receiver was compatible with the session request.${chrome.cast.ErrorCode.RECEIVER_UNAVAILABLE}`;
        case chrome.cast.ErrorCode.SESSION_ERROR:
            return `${chromeCastSplunkString} A session could not be created, or a session was invalid.${chrome.cast.ErrorCode.SESSION_ERROR}`;
        case chrome.cast.ErrorCode.TIMEOUT:
            return `${chromeCastSplunkString} The operation timed out.${chrome.cast.ErrorCode.TIMEOUT}`;
        default:
            return `${chromeCastSplunkString} Unknown error`;
    }
};

const injectChromecastSdk = () => {
    const id = "castSenderScript";
    if (!document.getElementById(id)) {
        let castFrameworkScript: HTMLScriptElement | null = null;
        castFrameworkScript = document.createElement("script");
        castFrameworkScript.type = "text/javascript";
        castFrameworkScript.id = id;
        castFrameworkScript.src =
            "//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1";
        document.getElementsByTagName("head")[0].appendChild(castFrameworkScript);
    }
};

const getChromeCastOptions = (): cast.framework.CastOptions => ({
    receiverApplicationId: "E2F0398B",
    autoJoinPolicy: chrome.cast.AutoJoinPolicy.PAGE_SCOPED,
});

window.castContext =
    window.castContext ??
    React.createContext<CastContextTypes>({
        remotePlayer: null,
        connectedPlayerId: PlayerIds.LIVE_PLAYER_ID,
        isChromecastAvailable: false,
        isChromecastConnected: false,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        setId: (id: PlayerIds | string) => {},
        isRemotePlayerPlaying: false,
        posDur: {position: 0, duration: 0},
    });

export const CastContext = window.castContext;

export const useChromecast = () => {
    const context = React.useContext(CastContext);

    if (!context) {
        throw new Error(`useChromecast must be used within the CastContextProvider`);
    }

    return context;
};

export function CastContextProvider({children}: {children: React.ReactNode}) {
    const remotePlayer = React.useRef<cast.framework.RemotePlayer | null>(null);
    const remotePlayerController =
        React.useRef<cast.framework.RemotePlayerController | null>(null);
    const [isChromecastAvailable, setIsChromecastAvailable] = React.useState(false);
    const [id, setId] = React.useState<PlayerIds | string>(PlayerIds.LIVE_PLAYER_ID);
    const [isChromecastConnected, setIsChromecastConnected] = React.useState(false);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [posDur, setPosDur] = React.useState({position: 0, duration: 0});
    const onPlay = React.useCallback(() => {
        setIsPlaying(true);
    }, []);

    const onPause = React.useCallback(() => {
        setIsPlaying(true);
    }, []);

    const setupRemoteListeners = React.useCallback(() => {
        if (!remotePlayerController.current) return;
        // Add event listeners for remote player, live player has no timebar
        if (id !== PlayerIds.LIVE_PLAYER_ID) {
            try {
                remotePlayerController.current.addEventListener(
                    cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
                    () => {
                        if (!remotePlayer.current) {
                            setPosDur({position: 0, duration: 0});
                        } else {
                            setPosDur({
                                position: remotePlayer.current.currentTime,
                                duration: remotePlayer.current.duration,
                            });
                        }
                    },
                );
            } catch (error: unknown) {
                log.warn(
                    `${chromeCastSplunkString} Cannot setupRemoteListeners for unknown reason`,
                    {error: serializeError(error)},
                );
            }
        }
    }, [id]);

    const removeRemoteListeners = React.useCallback(() => {
        if (!remotePlayerController.current) return;

        // Add event listeners for remote player, live player has no timebar
        if (id !== PlayerIds.LIVE_PLAYER_ID) {
            try {
                remotePlayerController.current.removeEventListener(
                    cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
                    () => {
                        if (!remotePlayer.current) {
                            setPosDur({position: 0, duration: 0});
                        } else {
                            setPosDur({
                                position: remotePlayer.current.currentTime,
                                duration: remotePlayer.current.duration,
                            });
                        }
                    },
                );
            } catch (error: unknown) {
                log.warn(
                    `${chromeCastSplunkString} Cannot removeRemoteListeners for unknown reason`,
                    {error: serializeError(error)},
                );
            }
        }
    }, [id]);

    const switchPlayer = React.useCallback(() => {
        if (cast?.framework && remotePlayer.current) {
            if (remotePlayer.current.isConnected) {
                setupRemoteListeners();
                setIsChromecastConnected(true);
                return;
            }
        }
        removeRemoteListeners();
        setIsChromecastConnected(false);
    }, [removeRemoteListeners, setupRemoteListeners]);

    const initializeCastPlayer = React.useCallback(() => {
        try {
            const options = getChromeCastOptions();
            cast.framework.CastContext.getInstance().setOptions(options);

            remotePlayer.current = new cast.framework.RemotePlayer();

            remotePlayerController.current = new cast.framework.RemotePlayerController(
                remotePlayer.current,
            );

            setIsChromecastAvailable(true);
        } catch (error: unknown) {
            log.warn(
                `${chromeCastSplunkString} Cannot initializeCastplayer for unknown reason`,
                {error: serializeError(error)},
            );
        }
    }, []);

    React.useEffect(() => {
        if (remotePlayerController.current && isChromecastAvailable) {
            remotePlayerController.current.addEventListener(
                cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
                switchPlayer,
            );
        }
        return () => {
            if (remotePlayerController.current) {
                remotePlayerController.current.removeEventListener(
                    cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
                    switchPlayer,
                );
            }
        };
    }, [isChromecastAvailable, switchPlayer]);

    const player: PlayerEngine = React.useMemo(
        () => ({
            engineName: PlayerBrands.CHROMECAST,
            load: async (source: string, shouldStartPlaying: boolean) => {
                const type = "application/x-mpegURL";
                let castSession: cast.framework.CastSession | null = null;
                try {
                    castSession =
                        cast.framework.CastContext.getInstance().getCurrentSession();
                } catch (error: unknown) {
                    log.warn(
                        `${chromeCastSplunkString} Cannot get castSession for unknown reason`,
                        {error: serializeError(error)},
                    );
                }

                if (!castSession) return;

                const mediaInfo = new chrome.cast.media.MediaInfo(source, type);
                mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
                mediaInfo.metadata.metadataType = chrome.cast.media.MetadataType.GENERIC;

                const request = new chrome.cast.media.LoadRequest(mediaInfo);
                castSession.loadMedia(request).then((errorCode) => {
                    if (!errorCode) return;
                    log.warn(getErrorMessage(errorCode), {errorCode});
                });
            },
            destroy: async () => {
                if (!remotePlayerController.current || !cast) return;

                remotePlayerController.current.removeEventListener(
                    cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
                    () => switchPlayer(),
                );
                remotePlayerController.current.removeEventListener(
                    cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
                    () => {
                        if (!remotePlayer.current) {
                            setPosDur({position: 0, duration: 0});
                        } else {
                            setPosDur({
                                position: remotePlayer.current.currentTime,
                                duration: remotePlayer.current.duration,
                            });
                        }
                    },
                );
                remotePlayerController.current.removeEventListener(
                    cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
                    () => {
                        if (!remotePlayer.current) return;
                        if (remotePlayer.current.isPaused) {
                            onPause();
                        } else {
                            onPlay();
                        }
                    },
                );

                try {
                    cast.framework.CastContext.getInstance().endCurrentSession(true);
                } catch (error: unknown) {
                    log.warn(
                        `${chromeCastSplunkString} Cannot end CurrentSession for unknown reason`,
                        {error: serializeError(error)},
                    );
                }
            },
            play: async () => {
                if (!remotePlayerController.current || !remotePlayer.current) return;

                if (remotePlayer.current.isPaused) {
                    remotePlayerController.current.playOrPause();
                }
                onPlay();
            },
            pause: async () => {
                if (!remotePlayerController.current || !remotePlayer.current) return;
                if (!remotePlayer.current.isPaused) {
                    remotePlayerController.current.playOrPause();
                }
                onPause();
            },
            seek: (newTime: number) => {
                if (!remotePlayer?.current || !remotePlayerController.current) return;
                remotePlayer.current.currentTime = newTime;
                remotePlayerController.current.seek();
            },
            mute: async () => {
                if (!remotePlayerController.current) return;

                remotePlayerController.current.muteOrUnmute();
            },
            unmute: async () => {
                if (!remotePlayerController.current) return;

                remotePlayerController.current.muteOrUnmute();
            },
            isPlaying: () => {
                if (!remotePlayer.current) return false;

                return !remotePlayer.current.isPaused;
            },

            addEventListener: (
                event: cast.framework.RemotePlayerEventType,
                callBack: () => void,
            ) => {
                if (!remotePlayerController.current) return;

                remotePlayerController.current.addEventListener(event, callBack);
            },
            removeEventListener: (
                event: cast.framework.RemotePlayerEventType,
                callBack: () => void,
            ) => {
                if (!remotePlayerController.current) return;

                remotePlayerController.current.removeEventListener(event, callBack);
            },
            getVolumePercentage: () => {
                if (!remotePlayer.current) return 0;

                return remotePlayer.current.volumeLevel;
            },
            getCurrentTime: () => remotePlayer.current?.currentTime ?? 0,
            getInternalPlayer: () => remotePlayer.current,

            setVolume: (volumePercentage: number) => {
                if (!remotePlayer.current || !remotePlayerController.current) return;

                remotePlayer.current.volumeLevel = volumePercentage;
                remotePlayerController.current.setVolumeLevel();
            },
        }),
        [onPause, onPlay, switchPlayer],
    );

    React.useEffect(() => {
        // Function called fom the chromecast sdk which keeps track on chromecast availability
        // Important to set function before loading the sdk: https://developers.google.com/cast/docs/web_sender/integrate#initialization
        // eslint-disable-next-line no-underscore-dangle
        window.__onGCastApiAvailable = (isAvailable) => {
            if (isAvailable) {
                setTimeout(() => initializeCastPlayer(), 1000);
            }
        };

        /**
         * Adding chromsecast sender script all the scripts needed for the ATG cast
         * More info on https://developers.google.com/cast/docs/web_sender
         */
        injectChromecastSdk();
    }, [initializeCastPlayer]);

    // Component will unmount
    React.useEffect(
        () => () => {
            player.destroy();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
    const value = React.useMemo(
        () => ({
            isRemotePlayerPlaying: isPlaying,
            remotePlayer: player,
            setId,
            posDur,
            connectedPlayerId: id,
            isChromecastAvailable,
            isChromecastConnected,
        }),
        [id, isChromecastAvailable, isChromecastConnected, isPlaying, player, posDur],
    );

    return <CastContext.Provider value={value}>{children}</CastContext.Provider>;
}
