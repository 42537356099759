import * as React from "react";
import {Modal} from "@atg-ui/toolkit";
import {ComponentLoader} from "@atg-shared/lazy";
import {LoadingIndicator} from "atg-ui-components";
import {useDisplayLandingScreen} from "../../hooks/useDisplayLandingScreen";
import {Content} from "./LandingScreen.styles";

function LandingScreen() {
    const {isLandingScreenModalOpen, setLandingScreenShown} = useDisplayLandingScreen();

    return (
        <Modal
            data-test-id="landing-screen-modal"
            open={isLandingScreenModalOpen}
            sx={{
                // to overlay ResponsibleGamingHeader
                zIndex: 2001,
            }}
        >
            <Content>
                <ComponentLoader
                    loader={import(
                        /* webpackChunkName: "LandingScreen" */
                        "../LandingScreenContent"
                    )}
                    setShown={setLandingScreenShown}
                    placeholder={<LoadingIndicator inverted />}
                />
            </Content>
        </Modal>
    );
}

export default LandingScreen;
