import {InitializationError, TimeoutError} from "@curity/identityserver-haapi-web-driver";

export const TIMEOUT_ERROR_MESSAGE = "Vänligen försök igen om en stund.";
export const DEFAULT_ERROR_MESSAGE = "Vänligen försök igen senare.";
export const SERVER_ERROR_MESSAGE =
    "Vi har svårt att nå vår server. Försök igen lite senare. Om problemet kvarstår, vänligen kontakta vår kundservice.";
export const SMS_VERIFY_ERROR = "Felaktig eller utgången kod.Var god försök igen";
export const SMS_VERIFY_TOO_MANY_ATTEMPTS =
    "Vi kunde inte verifiera din SMS-kod. Vänligen generera en ny kod och försök igen.";

const FALLBACK_ERROR_MESSAGE = "Något gick fel. Vänligen försök igen senare.";
export function handleErrorMessage(error: unknown) {
    if (
        error instanceof Error ||
        error instanceof InitializationError ||
        error instanceof TimeoutError
    ) {
        if (
            error instanceof TimeoutError ||
            error.message.toLocaleLowerCase().includes("timeout") //  InitializationError can contain a timeout error
        ) {
            return TIMEOUT_ERROR_MESSAGE;
        }
        return DEFAULT_ERROR_MESSAGE;
    }
    return String(error);
}

/**
 * This dictionary can probably replace some/all constants above
 */
export const errorMessages: Record<string, string> = {
    "Non matching IP address":
        'Om din iPhone har funktionen "Privat Reläservice" aktiverad kan du behöva inaktivera denna, alternativt försöka igen i en annan webbläsare.',
};

export function getFriendlyErrorMessage(serverError: string): string {
    return errorMessages[serverError] || FALLBACK_ERROR_MESSAGE;
}
