import {type IDToken} from "@atg-shared/auth/domain/accessTokenActions";

export const LOG = "appBridge/LOG";

export const LOGIN_LOADED = "appBridge/LOGIN_LOADED";
export const LOGIN_COMPLETE = "appBridge/LOGIN_COMPLETE";
export const LOGIN_CANCELLED = "appBridge/LOGIN_CANCELLED";
export const OPEN_COUPON = "appBridge/OPEN_COUPON";
export const DEPOSIT_FINISHED = "appBridge/DEPOSIT_FINISHED";
export const REFRESH_TOKEN = "appBridge/REFRESH_TOKEN";
export const ACCESS_TOKEN_REFRESHED = "appBridge/ACCESS_TOKEN_REFRESHED";
export const ACCOUNT_REMOVAL = "appBridge/ACCOUNT_REMOVAL";

export type LogMessageAction = {
    type: typeof LOG;
    payload: {message: string};
};

export type LoginLoadedPayload = {
    idToken?: IDToken;
    accessToken?: string;
};

export type LoginLoadedAction = {
    type: typeof LOGIN_LOADED;
    payload: LoginLoadedPayload;
};

export type LoginCompletePayload = {
    idToken: IDToken;
    accessToken: string;
    showBalance: boolean;
};

export type AccessTokenRefreshedPayload = {
    idToken: IDToken;
    accessToken: string;
};

export type LoginCompleteAction = {
    type: typeof LOGIN_COMPLETE;
    payload: LoginCompletePayload;
};

export type LoginCancelledAction = {
    type: typeof LOGIN_CANCELLED;
};

export type OpenCouponPayload = {
    couponID: string;
    gameID: string;
};

export type OpenCouponAction = {
    type: typeof OPEN_COUPON;
    payload: OpenCouponPayload;
};

export type DepositFinishedAction = {
    type: typeof DEPOSIT_FINISHED;
};

export type RefreshTokenAction = {
    type: typeof REFRESH_TOKEN;
};

export type AccessTokenRefreshedAction = {
    type: typeof ACCESS_TOKEN_REFRESHED;
    payload: AccessTokenRefreshedPayload;
};
export type AccountRemovalAction = {
    type: typeof ACCOUNT_REMOVAL;
};

export const log = (message: string): LogMessageAction => ({
    type: LOG,
    payload: {message},
});

export const loginLoaded = (
    idToken?: IDToken,
    accessToken?: string,
): LoginLoadedAction => ({
    type: LOGIN_LOADED,
    payload: {idToken, accessToken},
});

export const loginComplete = (
    idToken: IDToken,
    accessToken: string,
    showBalance: boolean,
): LoginCompleteAction => ({
    type: LOGIN_COMPLETE,
    payload: {idToken, accessToken, showBalance},
});

export const loginCancelled = (): LoginCancelledAction => ({
    type: LOGIN_CANCELLED,
});

export const openCoupon = (couponID: string, gameID: string): OpenCouponAction => ({
    type: OPEN_COUPON,
    payload: {couponID, gameID},
});

export const depositFinished = (): DepositFinishedAction => ({
    type: DEPOSIT_FINISHED,
});

export const refreshToken = (): RefreshTokenAction => ({
    type: REFRESH_TOKEN,
});

export const accessTokenRefreshed = (
    idToken: IDToken,
    accessToken: string,
): AccessTokenRefreshedAction => ({
    type: ACCESS_TOKEN_REFRESHED,
    payload: {idToken, accessToken},
});

export const accountRemoval = (): AccountRemovalAction => ({
    type: ACCOUNT_REMOVAL,
});

export type PostMessagePayload = string | LoginLoadedPayload | LoginCompletePayload;
