import * as React from "react";
import {ComponentLoader} from "@atg-shared/lazy";
import Features, {showNewFooter} from "@atg-shared/client-features";

function LazyFooter() {
    const isNewFooterEnabled = Features.isEnabled(showNewFooter);

    return isNewFooterEnabled ? (
        <ComponentLoader
            loader={import(/* webpackChunkName: "Footer" */ "./FooterNew/FooterNew")}
            placeholder={null}
        />
    ) : (
        <ComponentLoader
            loader={import(/* webpackChunkName: "Footer" */ "./Footer")}
            placeholder={null}
        />
    );
}
export default LazyFooter;
