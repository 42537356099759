import {fetchSelectors} from "@atg-shared/fetch-redux";
import {PostRequestStatus} from "@atg-aml-shared/inbox-types/src/inboxEnums";
import type {State} from "@atg-aml-shared/inbox-types";
import {createSelector} from "reselect";

export const getInboxMessages = (state: State) => state.inboxMessages?.conversations;

export const getNumberOfUnreadMessages = (state: State) =>
    state.inboxMessages.messageStatus.numberOfUnread;

export const getLoadingState = (state: State) =>
    fetchSelectors.getLoadingState(state.inboxMessages);

const getMessageStatusState = (state: State) => state.inboxMessages?.messageStatus;

export const getMessageStatus = createSelector(
    getMessageStatusState,
    (messageStatus: State["inboxMessages"]["messageStatus"]) => ({
        numberOfUnread: messageStatus?.numberOfUnread,
        hasUnanswered: messageStatus?.hasUnanswered,
    }),
);

export const getMessagePostRequestStatus = (state: State) => ({
    isIdle: state.inboxMessages?.postRequest?.status === PostRequestStatus.IDLE,
    isSubmitting:
        state.inboxMessages?.postRequest?.status === PostRequestStatus.SUBMITTING,
    isSuccessful:
        state.inboxMessages?.postRequest?.status === PostRequestStatus.SUCCESSFUL,
    isError: state.inboxMessages?.postRequest?.status === PostRequestStatus.ERROR,
    errorReason: state.inboxMessages?.postRequest?.errorReason,
});
