import * as React from "react";
import * as Storage from "@atg-shared/storage";
import {useMediaQuery} from "@atg-ui/toolkit/hooks";
import {useTheme} from "@atg-ui/toolkit/theme";
import {device} from "@atg/utils";
import {USER_STORAGE_KEY} from "@atg-global-shared/user";

const LANDING_SCREEN_SHOWN_STORAGE_KEY = "landingScreenShown";

interface Props {
    /** won't show modal if true */
    skipDisplay?: boolean;
}

export function useDisplayLandingScreen(props?: Props) {
    const theme = useTheme();

    const isMobile =
        useMediaQuery(theme.breakpoints.down("md")) && device.isTouchDevice();
    const modalOpened =
        !props?.skipDisplay &&
        window.location.pathname === "/" &&
        Storage.getItem(USER_STORAGE_KEY) === null &&
        Storage.getItem(LANDING_SCREEN_SHOWN_STORAGE_KEY) === null;
    const shouldRenderLandingScreen = modalOpened && isMobile;

    const [isModalOpen, setIsModalOpen] = React.useState(modalOpened); // not using shouldRenderLandingScreen here since isMobile might be false at first render even for mobile devices

    const setShown = () => {
        setIsModalOpen(false);
        Storage.setItem(LANDING_SCREEN_SHOWN_STORAGE_KEY, "true");
    };

    React.useEffect(() => {
        if (!modalOpened) {
            setShown();
        }
    }, [modalOpened]);

    return {
        shouldRenderLandingScreen,
        isLandingScreenModalOpen: isModalOpen,
        setLandingScreenShown: setShown,
    };
}
