import {reduce} from "lodash";
import * as FeatureNames from "@atg-shared/client-features/featureNames";
import type {ClientFeaturesType} from "@atg-shared/client-features";
import type {TillsammansClientConfig} from ".";

/**
 * If you want a feature to globaly have another default value then 'false'.
 */
const globalFeatureDefaults: Partial<ClientFeaturesType> = {
    [FeatureNames.tillsammansSpeltips]: true,
};

const features = reduce(
    FeatureNames,
    (acc, featureName) => ({
        ...acc,
        [featureName]: globalFeatureDefaults[featureName] ?? false,
    }),
    {},
) as ClientFeaturesType;

const mpx = {
    jwplayerkey: "BBM1ipFc45YNbiMzhGytitbypjdc4DtJQky/Ag&#x3D;&#x3D;",
};

const socialMedia = {
    // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
    facebookId: 10156928719710557,
    twitterId: "@abtravochgalopp",
};

const tillsammans: TillsammansClientConfig = {
    quicksearch: {
        enabled: true,
        surveyId: "4D0CFEBCZFFF6",
    },
};

const config = {
    features,
    mpx,
    socialMedia,
    tillsammans,
};

export default config;
