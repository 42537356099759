import * as FeatureNames from "@atg-shared/client-features/featureNames";
import baseConfig from "./all";
import type {ClientConfig, ClientSettings} from ".";

const config: ClientConfig = {
    systemName: "atgse",
    solaceWebsocketURL: "wss://push.atg.se/",
    solaceVpnName: "atgse",
    solacePassword: "web",
    searchUrl: "https://search.atg.se",
    videoArchiveSearchURL: "https://search.atg.se/videoarkiv",
    VMiV75URL: "https://atg.se/vmiv75",
    env: "prod",
    service: "https://www.atg.se",
    qubit: {
        env: "prod",
    },
    oneTrust: {
        url: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
        dataDomainScript: "488d3c93-fd26-45f0-8814-cb1a395ec706",
    },
    curity: {
        uri: "https://iam.atg.se",
        tokenHandlerURI: "https://login.atg.se/v1",
        tillsammansWicketLogoutURI: "https://tillsammans.atg.se/services/v1/user/logout",
        logoutURI: "https://login.atg.se/v1/logout",
    },
    countryEndpoint: "https://6n4r1rnzpa.execute-api.eu-north-1.amazonaws.com/v2/country",
    sport: {
        kambi: {
            offeringUrl: "https://eu-offering-api.kambicdn.com",
            widgetApi: "https://kambi-widget-api.bc.kambicdn.com/kambi-widget-api.js",
            bootstrapUrl: "https://client-static.bc.kambicdn.com",
            socketUrl: "wss://eu-push.kambicdn.com",
            graphql: "https://graphql.kambicdn.com",
            env: "prod",
        },
        cloudfrontUrl: "https://d2eelhowcp7um3.cloudfront.net/prod",
        big9PreMatchStatistics:
            "https://aaun1ux0fe.execute-api.eu-west-1.amazonaws.com/default/statistics",
        big9Poll: "https://hqmu2377rf.execute-api.eu-west-1.amazonaws.com/prod",
        contentful: {
            apiUrl: "https://graphql.contentful.com/content/v1/spaces/hoxdyoos3yge/environments/master",
            deliveryToken: "2FPRyJ38c44ERzeWRqtPqGi3HauLIGUA7794bwGtAc0",
        },
    },
    casino: {
        cloudfrontUrl: "https://d2wnqh0unhm9zq.cloudfront.net/content/dynamic/data",
        contentful: {
            apiUrl: "https://graphql.contentful.com/content/v1/spaces/6uzc8mm6ev87/environments/master",
            deliveryToken: "kCMqD2wykiuqQqWYLTw5zGPhvt7zpbE3Cos3WEIxlYg",
        },
        vendor: {
            playNGoUrl:
                "https://dmtcw.playngonetwork.com/casino/ContainerLauncher?pid=571&lang=sv_SE&embedmode=iframe",
            yggdrasilUrl:
                "https://staticlivese.yggdrasilgaming.com/init/launchClient.html?org=ATGCasino&lang=sv&currency=SEK",
            nolimitCity: {
                operator: "ATG",
                environment: "malta",
            },
            thunderkickUrl:
                "https://tk-game-mt1.thunderkick.com/gamelauncher/play/generic?operatorId=3031",
            isoftUrl: "https://game-launcher-lux.isoftbet.com/570",
            pragmaticPlay: {
                operator: "atg_atg",
                gameServerDomain: "atg-dk2.pragmaticplay.net",
                bingoOperator: "atg_atg",
                bingoServerDomain: "bingo-atg.pragmaticplay.net",
                miniGamesLobbyUrl: "https://djiuulvdazn5u.cloudfront.net",
            },
            elkUrl: "https://gamelauncher.contentmedia.eu/1.0/game/?operatorid=7770271&language=sv_se&currency=SEK",
            kironUrl:
                "https://games1.playbetman.com?o=30E96232-3116-4AD4-894C-EC50848C726E",
            kiron: {
                url: "https://games1.playbetman.com?o=30E96232-3116-4AD4-894C-EC50848C726E",
                bigs: {
                    url: "https://bigs.playbetman.com",
                    operator: "30e96232-3116-4ad4-894c-ec50848c726e",
                },
            },
        },
    },
    horse: {
        cloudfrontUrl: "https://d2jskzqs3w4wdx.cloudfront.net",
        graphqlHorse: "https://www.atg.se/services/horse-api/graphql",
        contentful: {
            apiUrl: "https://graphql.contentful.com/content/v1/spaces/hkip2osr81id/environments/master",
            deliveryToken: "HCEjbvpX8-d52BA_j-b8d5k1VvDHkuoTPRI2bProjr8",
        },
        publicApiUrl: "https://api.atg.se",
    },
    play: {
        graphqlPlay: {
            cdnApiUrl: "https://prod.streaming.aws.atg.se/prod",
            apiKey: "Not used",
        },
        muxEnvKey: "31o9a0s19fboiopu8bbbmo27n",
    },
    tillsammansBaseUrl: "https://tillsammans.atg.se",
    atgseURL: "https://www.atg.se",
    reCaptcha: {
        siteKey: "6LfVVEEaAAAAAP49_b_Y_P9mvGHPX_O8yM9kRAx1",
    },
    shopShares: {
        apiUrl: "https://www.atg.se/services/tokenized-proxy/shopshare/api/graphql",
    },
    genesys: {
        environment: "euc1",
        deploymentId: "b60bb28f-0d80-4c58-8665-b06f1a02f217",
    },
    aws: {
        imagesCloudfrontUrl: "https://images.atg.se",
        imagesCloudfrontUrlV2: "https://images.atg.se",
        staticImagesCloudfrontUrl: "https://d2anhoxr3i2ava.cloudfront.net",
    },
    tillsammans: {
        ...baseConfig.tillsammans,
        quicksearch: {
            enabled: true,
            surveyId: "4D0CFEBCZFFF6",
        },
    },
};

const features = {
    ...baseConfig.features,
    [FeatureNames.loginTimeLimit]: true,
    [FeatureNames.pushNotification]: false,
    [FeatureNames.radarDebugger]: false,
    [FeatureNames.bettingTrends]: false,
    [FeatureNames.newStartlist]: true,
    [FeatureNames.kycQuestionnaire]: true,
    [FeatureNames.rgsLimitForHorseAndSports]: true,
    [FeatureNames.playExperimental]: false,
    [FeatureNames.inboxInlineMessageForm]: true,
    [FeatureNames.julkalender]: false,
    [FeatureNames.sessionTimer]: false,
    [FeatureNames.horseOfTheYearPage]: true,
    [FeatureNames.loseMiniPlayer]: false,
    [FeatureNames.myAtg]: false,
    [FeatureNames.framtidensButik]: true,
    [FeatureNames.newShopOverviewPage]: true,
    [FeatureNames.newShopSearchPage]: true,
    [FeatureNames.avoidPermanentTillsammansRedirect]: false,
    [FeatureNames.tillsammansPush]: true,
    [FeatureNames.newJackpot]: true,
    [FeatureNames.selfExclusionsRGS]: true,
    [FeatureNames.newRgsLimitService]: true,
    [FeatureNames.lastVertical]: true,
    [FeatureNames.scheduledHeroUserSegments]: true,
    [FeatureNames.welcomeNewCustomer]: false,
    [FeatureNames.rcsOptins]: true,
    [FeatureNames.newVerticalNavbar]: true,
    [FeatureNames.big9SellBetV2]: false,
    [FeatureNames.paymentMicroFe]: false,
    [FeatureNames.newMiniPlayer]: false,
    [FeatureNames.newCurityFlow]: true,
    [FeatureNames.lastVerticalTillsammans]: false,
    [FeatureNames.closeMenuOnVerticalClick]: false,
    [FeatureNames.myAccountPage]: false,
    [FeatureNames.floaterButton]: false,
    [FeatureNames.daily5]: false,
    [FeatureNames.mobileTopMenu]: true,
    [FeatureNames.newPlayTopSection]: false,
    [FeatureNames.newsArchiveSearch]: false,
    [FeatureNames.useMaxStakePerSystem]: false,
    [FeatureNames.liveChannelsContext]: false,
    [FeatureNames.depositV2CreditCard]: true,
    [FeatureNames.showNewFooter]: true,
    [FeatureNames.microFrontendVideoPlayer]: false,
    [FeatureNames.newHeaderMenu]: false,
};

const clientSettings: ClientSettings = {
    config,
    features,
    socialMedia: baseConfig.socialMedia,
    mpx: baseConfig.mpx,
};

export default clientSettings;
