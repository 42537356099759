import {createSelector} from "reselect";
import type {MainNavigationState} from "./mainMenuReducer";

export type GlobalState = {
    mainMenu: MainNavigationState;
};

export const getSideMenu = (state: GlobalState) => state.mainMenu.sideMenu;
export const getDropdownMenu = (state: GlobalState) => state.mainMenu.dropdownMenu;
export const getStickyState = (state: GlobalState) => state.mainMenu.sticky;
export const isOverlayMenuOpen = (state: GlobalState) =>
    Boolean(getSideMenu(state)) && !getStickyState(state);
export const isStickySideMenuOpen = (state: GlobalState) =>
    Boolean(getSideMenu(state)) && getStickyState(state);

type RouterState = {
    router: {
        location: {
            pathname: string;
            hash: string | null | undefined;
        };
    };
};

export const getPath = (state: RouterState) =>
    state.router ? state.router.location.pathname : "";

export const getHash = (state: RouterState) => state.router?.location?.hash || "#home";

export const isVerticalLandingPage: (state: RouterState) => boolean = createSelector(
    getPath,
    getHash,
    (path, hash) => {
        const verticalLandingPaths = ["/", "/sport", "/casino", "/casino/start"];
        const isLandingPage: boolean = verticalLandingPaths.indexOf(path) !== -1;
        if (path === "/sport" && hash !== "#home") return false;
        return isLandingPage;
    },
);

export const isKundservicePage: (state: RouterState) => boolean = createSelector(
    getPath,
    (path) => path === "/kundservice",
);

export const isVerticalAccountPage: (state: RouterState) => boolean = createSelector(
    getPath,
    getHash,
    (path, hash) => {
        const accountLandingPaths = [
            "/konto/overforingar",
            "/konto/mina-spel",
            "/konto/mina-spel/sport",
            "/konto/mina-spel/big9",
            "/konto/kuponger",
            "/konto/kuponger/big9",
            "/konto/mina-meddelanden",
            "/konto/installningar",
            "/konto/harry-boy-prenumerationer",
            "/konto/granser",
            "/konto/mina-spelvanor",
            "/konto/sparra",
        ];
        const isAccountPage = accountLandingPaths.indexOf(path) !== -1;
        if (isAccountPage || (path === "/sport" && hash.startsWith("#bethistory")))
            return true;
        return false;
    },
);
